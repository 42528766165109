exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-aws-index-tsx": () => import("./../../../src/pages/aws/index.tsx" /* webpackChunkName: "component---src-pages-aws-index-tsx" */),
  "component---src-pages-aws-transactions-tsx": () => import("./../../../src/pages/aws/transactions.tsx" /* webpackChunkName: "component---src-pages-aws-transactions-tsx" */),
  "component---src-pages-buying-js": () => import("./../../../src/pages/buying.js" /* webpackChunkName: "component---src-pages-buying-js" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-faqs-tsx": () => import("./../../../src/pages/faqs.tsx" /* webpackChunkName: "component---src-pages-faqs-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-merchant-tsx": () => import("./../../../src/pages/merchant.tsx" /* webpackChunkName: "component---src-pages-merchant-tsx" */),
  "component---src-pages-multi-currency-js": () => import("./../../../src/pages/multi-currency.js" /* webpackChunkName: "component---src-pages-multi-currency-js" */),
  "component---src-pages-plg-js": () => import("./../../../src/pages/plg.js" /* webpackChunkName: "component---src-pages-plg-js" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-slg-js": () => import("./../../../src/pages/slg.js" /* webpackChunkName: "component---src-pages-slg-js" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */)
}

